
import { upcomingDate } from '~/mixins/course-date-utils'


export function putManualListFirst(courses, extra_data)
{
	// debugger;
	if (!courses)	return [];
	if (!extra_data || !Array.isArray(extra_data))	return courses;

	let manual_list = [];
	let tail_list = [];

	extra_data.forEach(course => {
		const found = courses.find(x => x.course_id == course.course_id);
		if (found)
			manual_list.push(found);
	})

	tail_list = courses.filter(c => manual_list.indexOf(c) == -1 );

	return manual_list.concat( tail_list );
}


export function sortCourses(courses)
{
	console.log('sortCourses()')
	const list = [...(courses || [])].sort((a, b) =>
	{
		const a_upcoming = upcomingDate(a.dates);
		const b_upcoming = upcomingDate(b.dates);
		if (!a_upcoming && !b_upcoming)	return 0;
		if (!a_upcoming)	return 1;
		if (!b_upcoming)	return -1;

		let a_time = ( a_upcoming ).getTime();
		let b_time = ( b_upcoming ).getTime();

		if (a_time < b_time)	return -1;
		if (a_time > b_time)	return 1;
		return 0;
	})

	return list;
}
