
export default function detectWebp()
{
	var i = new Image;
	var parentResolve = null;
	var parentReject = null;
	var promise = new Promise( (resolve, reject) =>
	{
		parentResolve = resolve;
		parentReject = reject;
	} );
	i.onload = i.onerror = function () 
	{
		parentResolve( i.height == 1 );
		// document.body.classList.add(i.height == 1 ? "webp" : "no-webp")
	};
	i.src = "data:image/webp;base64,UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==";

	return promise;
}
