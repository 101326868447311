import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d2471b08&lang=pug&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TextFx: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/text-fx.vue').default,RestProp: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/rest-prop.vue').default,RestPropLink: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/rest-prop-link.vue').default,RestImg: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/rest-img.vue').default,HeaderSidebar: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/header-sidebar.vue').default,CmsEdit: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/cms-edit.vue').default,RestModel: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/rest-model.vue').default,Carousel: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/carousel.vue').default,Domel: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/domel.vue').default,RestRichText: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/rest-rich-text.vue').default,RestString: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/rest-string.vue').default,Quotes: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/quotes.vue').default,FullCourseList: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/full-course-list.vue').default,FaqList: require('/Users/n.yiannopoulos/Documents/Projects/el-translations-fullstack/resources/components/faq-list.vue').default})
