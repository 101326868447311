
// import 'hooper/dist/hooper.css'
import Vue from 'vue'
const consola = require('consola'); consola.level = process.env.CONSOLA_LEVEL;
import { mapGetters, mapMutations, mapActions } from 'vuex'

import domel from '~/components/domel'
// import RestModelDummy from '~/components/rest-model-dummy'
// import RestImgDummy from '~/components/rest-img-dummy'
// import RestStringDummy from '~/components/rest-string-dummy'
// import RestStringLinkDummy from '~/components/rest-string-link-dummy'
// import RestPropDummy from '~/components/rest-prop-dummy'
// import RestPropLinkDummy from '~/components/rest-prop-link-dummy'
// import RestDateDummy from '~/components/rest-date-dummy'
// import RestRichTextDummy from '~/components/rest-rich-text-dummy'
// import RestReorderListDummy from '~/components/rest-reorder-list-dummy'
// import CmsEditDummy from '~/components/cms-edit-dummy'

import HeroSection from '~/components/hero-section'
import TextFx from '~/components/text-fx'
import svgSymbol from '~/components/svg-symbol'
import ctaArrow from '~/components/cta-arrow'
import Carousel from '~/components/carousel'
import Accordion from '~/components/accordion'
import FaqList from '~/components/faq-list'
import capitalize from '~/src/utils/capitalize'

import HeaderMetaMixin from '~/mixins/header-meta'
import AnnouncementsMixin from '~/mixins/announcements'
import InstructorModalMixin from '~/mixins/open-instructor-modal'
// import { head } from '~/mixins/header-meta'
import CourseDateUtils from '~/mixins/course-date-utils'

import HeaderSidebar from '~/components/header-sidebar'
// import Quotes from '~/components/quotes'
// import { Carousel, Slide } from 'vue-carousel';
// import { Carousel, Slide } from '~/lib/vue-carousel';
// import { Hooper, Slide } from 'hooper';
// import RestImgDummy from '~/components/rest-img-dummy'

import Intersect from '~/components/vue-intersect'
import ScrollObserver from '~/components/scroll-observer-raf'
import LazyHydrate from 'vue-lazy-hydration';

const endsWith = require('lodash.endswith')

// this is how you can get the path of an image asset
// import pinSvg from '~/assets/svg/pin.svg'

// import yamlData from '~/assets/test.yaml'

// const page_slug = "/academy";
// const page_slug = "/";

export default {

	layout: 'academy',

	mixins: [HeaderMetaMixin, CourseDateUtils, AnnouncementsMixin, InstructorModalMixin],

	components:
	{
		LazyHydrate,

		// 'rest-img': RestImgDummy,// RestImg,
		// 'rest-prop': RestPropDummy, // RestProp,
		// 'rest-rich-text': RestRichTextDummy,// RestRichText,
		// 'rest-model': RestModelDummy,// RestModel,
		// 'rest-prop-link': RestPropLinkDummy, // 'rest-link': RestPropLink,
		// 'rest-link': RestPropLinkDummy, // 'rest-link': RestPropLink,
		// 'rest-string': RestStringDummy, // RestString,
		// 'rest-string-link': RestStringLinkDummy,// RestStringLink,
		// 'rest-date': RestDateDummy, // RestDate,
		// 'rest-date-add': domel,// RestDateAdd,
		// 'rest-reorder-list': RestReorderListDummy,//RestReorderList,
		// 'cms-edit': CmsEditDummy,
		// 'rest-list-item-add': domel,// RestListItemAdd,
		// 'rest-item-add': domel,// RestItemAdd,
		// 'rest-item-removable': domel,// RestItemRemovable,
		// 'tag-input': domel,// TagInput,

		// you *MUST* declare components before you can use them
		ctaArrow,
		svgSymbol,
		TextFx,
		HeroSection,
		// Carousel, Slide,
		// Hooper, Slide,
		Carousel,
		Accordion,
		FaqList,
		HeaderSidebar,
		// Quotes,
		Intersect,
		ScrollObserver,
	},

	// this overrides the HeadMeatMixin
	// head: { bodyAttrs: { "class": ["page-academy"] } },

	data()
	{
		return {
			bodyClasses: ["page-academy"],
			pageLoaded: false,
			pageData: { block_list:  [{}, {}, {}, {}, {}, {}, {}, {}, {}, ], },
			// blocks: [{}, {}, {}, {}, {}, {}, {}, {}, {}, ],

			currentSectionKey: 'key-benefits',

			animsOnce: true,

			heroSubtitleStyle: {
				// opacity: 0
				opacity: 1
			},

			sidebarSubtextStyle: {
				transition: 'none',
				opacity: 0,
			},

			sidebarClasses: ['Head-Sidebar--hide-left'],
			quoteIsVisible: false,

			// courseData: null,
			// currentSectionKey: 'key-benefits',

			courses: [
			],

			carouselOffsetX: 0,
			prevTouchX: 0,

			selected_faq_cat: 'All',


			isEditingPage: false,

		};
	},


	async asyncData({app, params, store, error, req, route})
	{
		let result = null;
		// const lang = params.lang;
		const lang = 'el';
		let page_slug = route.path.replace('/edit','');//.slice(3);
		page_slug = page_slug || '/';
		consola.debug('academy slug: ', page_slug);

		// try {
			// consola.debug('route: ', route)
			// consola.debug('req: ', req)
			// const result = await app.$apiGet('page-slug/'+params.slug, { locale: store.getters.lang } )
			// consola.debug(`asyncData req:`, req)
			store.dispatch('setLang', { lang: lang });
			store.dispatch('setDeviceWidthFromUserAgent');

			return Promise.all([
									app.$apiGet('page-slug/', { slug: page_slug, site_id: 2, locale: lang } ),
									app.$apiGet('course', { locale: lang, is_published: 1 } ),
							])
					.then( list =>
					{
						consola.debug('asyncData list.length: ', list.length);
						// consola.debug('asyncData typeof list[0]: ', typeof list[0]);
						consola.debug('asyncData keys list[1]: ', Object.keys(list[1]));
						const pageData = list[0].data[0];
						if (pageData.locale)
						{
							consola.debug('page locale: ', pageData.locale )
							store.commit('set_lang', { lang: pageData.locale })
						}

						const menu = pageData.menu && pageData.menu.json || [];
						store.commit('setPageMenu', menu);

						const footer = pageData.footer && pageData.footer.json || [];
						store.commit('setPageFooter', footer);

						return {
									pageData,
									// blocks: list[0].data[0].block_list,
									courses: list[1].data
								}
					} )
					.catch( error =>
					{
						consola.debug(`academy.js[175] error: `, error)
						return {};
					})

	},


	watch:
	{
		// lang(newVal, prevVal)
		// {
		// 	consola.debug('language was changed, retrieving new data...')
		// 	this.$apiLoadCourseData('masterclass-in-translation')
		// 		.then((result)=>
		// 		{
		// 			this.courseData = result.results ? result.results[0].data : result.data;
		// 		})
		// 		.catch((error)=>
		// 		{
		// 			consola.debug('an error occured when trying to retrieve the lang: ', error);
		// 		})
		// },

		pageLoaded(newVal)
		{
			setTimeout( ()=>
			{
				if (newVal)
					this.sidebarClasses = [];
			},
			1000 )
		},
	},

	computed:
	{
		// ...mapGetters(['commonWords', 'getString', 'lang', 'langShort', 'allLanguages', 'isTablet', 'isLargerThanTabletLandscape']),
		...mapGetters([/*'isEditingPage',*/ 'getString', 'lang', 'langShort', 'allLanguages', 'adminPanelRef', 'windowWidth', 'isMobile', 'isTablet', 'isTabletOrBigger', 'isLargerThanTabletLandscape', 'isLaptop', 'isLaptopOrBigger', 'isDesktop', 'windowWidth', 'scrollTop']),

		blocks()
		{
			return this.pageData.block_list;
		},

		headBlock()
		{
			let block = this.blocks.filter(x => x.block_type == 'head')[0];
			if (!block)	debugger;
			return block;
		},

		featuredBlock()
		{
			let block = this.blocks.filter(x => x.block_type == "featured")[0];
			if (!block)	debugger;
			return block;
		},

		quoteBlock()
		{
			let block = this.blocks.filter(x => x.block_type == "quotes")[0];
			if (!block)	debugger;
			return block;
		},

		faqs()
		{
			let block = this.blocks.filter(x => x.block_type == "faqs")[0];
			if (!block)	debugger;
			return block;
		},

		courseBlock()
		{
			let block = this.blocks.filter(x => x.block_type == "courses" && x.block_id!=907 )[0];
			if (!block)	debugger;
			return block;
		},

		courseListBlock()
		{
			let block = this.blocks.filter(x => x.block_type == "courses" && x.block_id==907 )[0];
			if (!block)	debugger;
			return block;
		},

		// courseBlock()
		// {
		// 	return this.blocks.filter(x => x.block_type == 'courses' && x.block_id!=907)[0];
		// },

		// courseListBlock()
		// {
		// 	return this.blocks.filter(x => x.block_type == 'courses' && x.block_id==907 )[0];
		// },

		instructors()
		{
			let block = this.blocks.filter(x => x.block_type == "instructors")[0];
			if (!block)	debugger;
			return block;
		},

		publishedInstructors()
		{
			return this.publishedOnly(this.instructors.extra_data);
		},

		carouselStyle()
		{
			return `transform: translate3d(${this.carouselOffsetX}px, 0px, 0px)`;
		},

		filteredFaqs()
		{
			if (this.selected_faq_cat && this.selected_faq_cat != 'All')
			{
				const cat = this.selected_faq_cat.toLowerCase()
				return this.faqs.filter(x => x.cat.toLowerCase() == cat)
			}

			return this.faqs
		},

		faqsCategories()
		{
			let list = ['All']
			let dict = {}
			this.faqs.forEach(x => {
				const cat = capitalize(x.cat)
				if (dict[cat]) return
				list.push( cat )
				dict[ cat ] = true
			})

			return list
		},

	},

	created()
	{
		this.sortCourses();
	},

	// this is called when the children have been mounted and the component is ready
	mounted()
	{
		consola.debug('blocklist: ', this.blocks)
		consola.debug('courses: ', this.courses)
		// this.$apiLoadCourseData('masterclass-in-translation').then((result)=>
		// {
		// 	consola.debug('result: ', result);
		// })
		// .catch((error)=>
		// {
		// 	console.error(error);
		// })

		// consola.debug('yaml test: ', yamlData);
		// consola.debug('courseData: ', this.courseData);
		// consola.debug('courseData: ', JSON.stringify(this.courseData) );

		this.registerEvents();


		this.$nextTick( ()=>
		{
			setTimeout( ()=>
			{
				this.pageLoaded = true;
				if ( this.$refs.sidebar )
					this.$refs.sidebar.selected_idx = 0;
			}, 10 )
		} )

		this.heroSubtitleStyle.opacity = 0;

		setTimeout(
			() => {
				this.heroSubtitleStyle.opacity = 1;
				consola.debug('heroSubtitleStyle.opacity: ', this.heroSubtitleStyle.opacity)
			},
			1000
		)

		// this.isEditingPage = this._name == "<CorporateEdit>";
		this.isEditingPage = endsWith(this.$vnode.key, 'edit');

		this.$store.commit('setEditingPage', { editing: this.isEditingPage })

		let upcoming = this.courses.filter(x => this.upcomingDate(x.dates) )
		consola.debug('upcoming courses: ', upcoming);

	},

	beforeDestroy()
	{
		this.unregisterEvents()
	},

	methods:
	{
		registerEvents()
		{
			// if ( typeof window !== 'undefined' )
			// {
			// 	this.resizeCallback = () => this.onWindowResize()
			// 	window.addEventListener('resize', this.resizeCallback )
			// }
			// this.$root.$on( 'show-quote-form', this.onShowQuoteForm )
			this.$eventBus.$on( 'rest:response', this.updateData )
			this.$eventBus.$on( 'set-page-data', this.setPageData )
		},

		unregisterEvents()
		{
			// if ( typeof window !== 'undefined' )
			// {
			// 	if (this.resizeCallback)
			// 		window.removeEventListener('resize', this.resizeCallback )
			// }
			// this.$root.$off( 'show-quote-form', this.onShowQuoteForm )
			this.$eventBus.$off( 'rest:response', this.updateData )
			this.$eventBus.$off( 'set-page-data', this.setPageData )
		},

		openBlockModal(event, item)
		{
			event && event.preventDefault();
			consola.debug('openBlockModal')
			this.$root.$emit('open-modal', {...item} );
		},

		updateData(modelResponse)
		{
			this.fetchData()
		},

		setPageData(data)
		{
			consola.debug('setPageData')

			let scrollY = 0;
			if (typeof window !=='undefined')
				scrollY = document.documentElement.scrollTop || window.pageYOffset || document.body.scrollTop;


			this.pageData = data;
			let menu = this.pageData.menu && this.pageData.menu.json || [];
			this.$store.commit('setPageMenu', menu);

			const footer = this.pageData.footer && this.pageData.footer.json || [];
			this.$store.commit('setPageFooter', footer);
			// this.blocks = this.pageData.block_list;

			if (typeof window !=='undefined')
			{
				setTimeout( ()=>
				{
					if (document.documentElement.scrollTop)	document.documentElement.scrollTop = scrollY;
					if (window.pageYOffset)					window.pageYOffset = scrollY;
					if (document.body.scrollTop)			document.body.scrollTop = scrollY;
				}, 0);
			}
		},

		blockIndex(block)
		{
			for (let i = 0; i < this.blocks.length; i++)
			{
				if (block == this.blocks[i])	return i;
				if (block.block_type == this.blocks[i].block_type)	return i;
			}

			return null;
		},

		sortCourses()
		{
			consola.debug('sortCourses()')
			this.courses = this.courses.sort((a, b) =>
			{
				const a_time = ( this.upcomingDate(a.dates) || new Date() ).getTime();
				const b_time = ( this.upcomingDate(b.dates) || new Date() ).getTime();

				if (a_time < b_time)	return -1;
				if (a_time > b_time)	return 1;
				return 0;
			})
		},

		fetchData()
		{
			this.$nuxt.$loading.start();
			let page_slug = this.$route.path.replace('/edit','');//.slice(3);
			page_slug = page_slug || '/';

			return Promise.all([
							this.$apiGet('page-slug/', { slug: page_slug, site_id: 2, locale: this.lang } ),
							this.$apiGet('course', { locale: this.lang, is_published: 1 } ),
					])
			.then( list =>
			{
				consola.debug('fetchData list.length: ', list.length);
				// consola.debug('asyncData typeof list[0]: ', typeof list[0]);
				consola.debug('fetchData keys list[1]: ', Object.keys(list[1]));
				// if (list[0].data[0].locale)
				// {
				// 	consola.debug('page locale: ', list[0].data[0].locale )
				// 	this.$store.commit('set_lang', { lang: list[0].data[0].locale })
				// }

				this.pageData = list[0].data[0];
				this.blocks = list[0].data[0].block_list;
				this.courses = list[1].data;
			} )
			.catch( error =>
			{
				consola.debug(`${__filename}[361] error: `, error)
				return {};
			})
			.finally( () =>
			{
				this.$nuxt.$loading.finish();
			})
		},

		revealHead()
		{
			// sample methods function
		},

		publishedOnly(list)
		{
			if (!list || !Array.isArray(list))	return [];
			return list.filter(x => (typeof x.is_published=='undefined' || !!x.is_published)) || [];
		},

		onPanHorizontal(event)
		{
			// consola.debug('onPanHorizontal: ', event)
			this.carouselOffsetX = this.prevTouchX + event.deltaX;
			// this.carouselOffsetX = event.velocityX;
			consola.debug('carouselOffsetX: ', this.carouselOffsetX)
		},

		onPanStart(event)
		{
			consola.debug('onPanStart: ', event)
			this.prevTouchX = this.carouselOffsetX;
		},

		onPanEnd(event)
		{
			consola.debug('onPanEnd: ', event)
		},

		onPanMove(event)
		{
			consola.debug('onPanMove: ', event)
		},


		onChangeFaqCategory(cat)
		{
			this.selected_faq_cat = cat
			this.$refs.accordion.closeAll()
		}

	}
}
